export default {
    data() {
        return {
            subscription: null
        }
    },
    methods: {
        async subscribe(notification) {
            console.log('here we are')
            // Request permission to show notifications
            const result = await Notification.requestPermission();

            if (result === 'granted') {
                new Notification(notification);
            }
        },
    }
}