<template>
  <div id="wrapper">
    <sidebar :page="'Home'" :sidebar_data=sidebar_data></sidebar>

    <nav class="navbar bg-transparent position-absolute w-100" style="z-index: 1">
      <div class="container-fluid">
        <div class="navbar-brand bars" @click="this.$router.push({name: 'Home'})">
          <i class="ph-arrow-left text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
        </div>

        <span class="navbar-left">
          <div class="navbar-brand">
            <span style="display: -webkit-inline-box;">
              <h4 style="margin-top: 0.2rem">
              <i class="ph-bell-ringing text-white me-3 nav-icon circle p-2 bg-orange-secondary"></i>
              </h4>
            </span>
          </div>      
        </span>

        <div class="divider mt-4 d-none"></div>
      </div>
    </nav>

    <div class="backdrop" @click="close()"></div>
    <transition v-if="screen == 0" name="fade">  
      <delivery-details v-model:data=data v-model:screen=screen></delivery-details>
    </transition>

    <transition v-if="screen == 1" name="fade">  
      <payment-details v-model:data=data v-model:screen=screen></payment-details>
    </transition>

    <transition v-if="screen == 2" name="fade">  
      <payment-details v-model:data=data v-model:screen=screen></payment-details>
    </transition>  

    <transition v-if="screen == 3" name="fade">  
      <payment-confirmed></payment-confirmed>
    </transition>  

    <div id="map" style="height: 100%"></div>       
  </div>
</template>

<style lang="scss" scoped>
  @import '../assets/scss/layout/_navbar.scss';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Sidebar from '../components/AutoSidebar'
  import mapFunctions from '../mixins/mapFunctions'
  import DeliveryDetails from '../components/Delivery/DeliveryDetails'
  import PaymentDetails from '../components/Delivery/PaymentDetails'
  import PaymentConfirmed from '../components/Delivery/PaymentConfirmed'

  export default {
    name: 'Home',
    props: {
      delivery_data: {
        required: true,
        type: Object
      }
    },
    mixins: [mapFunctions],
    components: {
      Sidebar,
      DeliveryDetails,
      PaymentDetails,
      PaymentConfirmed
    },   
    data() {
      return {
        sidebar_data: [
          {title: 'Home', link: '', if_children: false, icon: 'home', modal: false},
          {title: 'My Account', link: 'profile', if_children: false, icon: 'user', modal: false},
        ],
        screen: 0,
        data: this.delivery_data
      }
    },
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      }       
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } 
      },
      formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }    
    }    
  }
</script>
