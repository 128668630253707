<template>
    <div>
        <div class="content bg-white position-fixed bottom-0 w-100" style="z-index: 1; border-top-right-radius: 5%; border-top-left-radius: 5%; border: 1px solid #eee;"> 
            <div class="card-body mb-3">
                <h5 class="content-title text-orange mt-2 mb-0 fw-bold">Payment Details</h5>
                <small class="text-muted mb-4">Choose how you want to pay for your delivery.</small>

                <div class="container">
                    <div class="row">
                        <div class="col">
                            <status v-model:status_data=status_data></status>
                        </div>
                    </div>
                </div>

                <ul class="list-group list-group-striped mt-4">
                    <span v-if="payment_details_screen == 0">
                        <li id="mpesa" class="list-group-item border-0 pt-3 pb-3" @click="this.payment_details_screen = 1">
                            <div class="d-flex">
                                <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />

                                <span class="fw-bold text-uppercase"> MPesa </span>
                                
                                <font-awesome-icon class="icon text-success ms-auto" :icon="['fa', 'money-bill-wave']" />
                            </div>
                            <small class="text-muted">Pay with MPesa </small>
                        </li>

                        <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>  

                        <li id="cash" class="list-group-item border-0 pt-3 pb-3" @click="showConfirm('cash')" data-bs-toggle="modal" data-bs-target="#confirm-cash">    
                            <div class="d-flex">
                                <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                
                                <span class="fw-bold text-uppercase"> Cash </span>
                                
                                <font-awesome-icon class="icon text-success ms-auto" :icon="['fa', 'money-bill-wave']" />
                            </div>
                            <small class="text-muted">Pay with Cash after delivery</small>
                        </li>

                        <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>  
                    </span> 

                    <!-- mpesa details -->
                    <span v-if="payment_details_screen == 1">

                        <li id="mpesa" class="list-group-item border-0 pt-3 pb-3" @click="showConfirm('mpesa')" data-bs-toggle="modal" data-bs-target="#confirm">
                            <div class="d-flex">
                                <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />

                                <span class="fw-bold text-uppercase"> MPesa Prompt </span>
                                
                                <font-awesome-icon class="icon text-success ms-auto" :icon="['fa', 'money-bill-wave']" />
                            </div>
                            <small class="text-muted">Get an M-Pesa Prompt directly to your phone </small>
                        </li>

                        <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div> 

                        <li id="buy-goods" class="list-group-item border-0 pt-3 pb-3" @click="showConfirm('buy_goods')" data-bs-toggle="modal" data-bs-target="#confirm-cash">
                            <div class="d-flex">
                                <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                
                                <span class="fw-bold text-uppercase"> Buy Goods and Services </span>
                                
                                <font-awesome-icon class="icon text-success ms-auto" :icon="['fa', 'money-bill-wave']" />
                            </div>
                            <small class="text-muted">Pay through our MPesa Till Number 9519911 on delivery</small>
                        </li>

                        <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>

                        <li id="buy-goods" class="list-group-item border-0 pt-3 pb-3" @click="this.payment_details_screen = 0">
                            <div class="d-flex">
                                <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                
                                <span class="fw-bold text-uppercase"> 
                                    <font-awesome-icon class="me-2" :icon="['fa', 'angle-left']"></font-awesome-icon>
                                    Other payment options 
                                </span>
                                
                                <font-awesome-icon class="icon text-success ms-auto" :icon="['fa', 'money-bill-wave']" />
                            </div>
                        </li>                    
                    </span>                                           
                </ul>

                <div class="d-flex">
                    <button type="button" class="btn bg-transparent btn-sm mt-4" @click="this.$emit('update:screen', 0)">
                        <font-awesome-icon class="me-2 text-orange" :icon="['fa', 'angle-left']" /> <span class="text-orange fw-bold">Review Details</span>
                    </button>    

                    <router-link :to="{name: 'CancelDelivery'}" class="btn btn-secondary btn-sm mt-4 ms-auto">
                        <font-awesome-icon class="me-2" :icon="['fa', 'times']" />  Cancel   
                    </router-link>
                </div>
                
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="confirm" tabindex="-1" aria-labelledby="confirmLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content rounded-4 border-0">
                    <div class="modal-body">
                        <span v-if="payment_modal_screen == 0">
                            <div class="row pt-2 pb-2">
                                <h4 class="fw-bold mb-3 text-orange mb-0">Confirm Payment through MPesa</h4>
                                
                                <ul class="list-group list-group-striped mt-4">
                                    <li id="cart-items" class="list-group-item border-0 d-flex pt-3 pb-3"  v-for="({name, price, quantity}, index) in cart_stock"  :key="index">
                                        <span class="fw-bold text-uppercase"> {{ name }} </span>

                                        <span class="fw-bold ms-auto"> Ksh. {{ price*quantity }} </span>
                                    </li>

                                    <li class="list-group-item border-0 d-flex pt-3 pb-3" v-if="prescribed">
                                        {{  }}
                                        <span class="fw-bold text-uppercase"> Request Prescription </span>

                                        <span class="fw-bold ms-auto"> Ksh. 250 </span>
                                    </li>

                                    <li id="delivery-details" class="list-group-item border-0 d-flex pt-3 pb-3">
                                        <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                        <span class="fw-bold text-uppercase"> Delivery </span>
                                        <span class="fw-bold ms-auto" >Ksh. {{ formatNumber(delivery_data.price) }} </span>
                                    </li>

                                    <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>

                                    <li id="total-price" class="list-group-item border-0 d-flex pt-3 pb-3">
                                        <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                        <span class="fw-bold text-uppercase text-orange"> Total Price </span>
                                        <span class="fw-bold ms-auto text-orange" >Ksh. {{ total_price }} </span>
                                    </li>
                                </ul>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-success delivery-button btn-sm float-end" @click="this.payment_modal_screen = 1">
                                        Next <font-awesome-icon class="icon ms-2" :icon="['fa', 'angle-right']" /> 
                                    </button>  

                                    <button type="button" class="btn btn-secondary delivery-button btn-sm me-2 float-end" data-bs-dismiss="modal" @click="showConfirm('mpesa')">
                                        Cancel <font-awesome-icon class="icon ms-2" :icon="['fa', 'times']" />     
                                    </button>                              
                                </div>                        
                            </div>
                        </span>

                        <span v-if="payment_modal_screen == 1">
                            <div class="row pt-2 pb-2">
                                <h4 class="fw-bold mb-3 text-orange mb-0">Phone Number to pay with</h4>

                                <div class="col-12">
                                    <div class="alert fixed alert-dismissible alert-success fade show" role="alert">
                                        <h6 class="text-start fw-bold text-uppercase">  <font-awesome-icon class="icon me-2" :icon="['fa', 'info-circle']" /> To note </h6>

                                        <p class="mb-0"> This is the Phone number we'll send the MPesa prompt to. Feel free  to change the number here.</p>
                                    </div> 
                                </div>                           

                                <div class="col-12 d-flex">
                                    <div class="d-block">
                                        <h6 class="text-dark">Current number:</h6> {{ phone_number }}

                                        <div class="form-row mb-2" v-if="change_phone_number">
                                            <label class="fw-bold mt-3" for="phone_number">Phone Number</label>
                                            <input id="phone_number" type="text" class="form-control" v-model="phone_number" >
                                        </div>
                                    </div>

                                    <div class="d-block ms-auto mt-2">
                                        <font-awesome-icon class="text-dark" :icon="['fa', 'user-edit']" @click="this.change_phone_number = true"></font-awesome-icon>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-success delivery-button btn-sm float-end" @click="payMPesa()" data-bs-dismiss="modal">
                                        Confirm <font-awesome-icon class="icon ms-2" :icon="['fa', 'check-circle']" /> 
                                    </button>  

                                    <button type="button" class="btn btn-secondary delivery-button btn-sm me-2 float-end" data-bs-dismiss="modal" @click="showConfirm('mpesa')">
                                        Cancel <font-awesome-icon class="icon ms-2" :icon="['fa', 'times']" />     
                                    </button>                              
                                </div>                        
                            </div>
                        </span>      
                    </div>
                </div>
            </div>
        </div>  

            <!-- Modal -->
        <div class="modal fade" id="confirm-cash" tabindex="-1" aria-labelledby="confirmLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content rounded-4 border-0">
                    <div class="modal-body">
                        <div class="row pt-2 pb-2">
                            <h4 class="fw-bold mb-3 text-orange mb-0">Confirm Payment</h4>

                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="alert fixed alert-dismissible alert-success fade show" role="alert">
                                            <h6 class="text-start fw-bold text-uppercase">  <font-awesome-icon class="icon me-2" :icon="['fa', 'info-circle']" /> To note </h6>

                                            <p class="mb-0 pt-2"> Please make payment upon delivery of the product. </p>
                                            <button type="button" class="btn-close" aria-label="Close" @click="hideAlert()"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <ul class="list-group list-group-striped mt-4">

                                <li id="cart-items" class="list-group-item border-0 d-flex pt-3 pb-3"  v-for="({name, price, quantity}, index) in cart_stock"  :key="index">
                                    <span class="fw-bold text-uppercase"> {{ name }} </span>

                                    <span class="fw-bold ms-auto"> Ksh. {{ price*quantity }} </span>
                                </li>

                                <li id="delivery-details" class="list-group-item border-0 d-flex pt-3 pb-3">
                                    <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                    <span class="fw-bold text-uppercase"> Delivery </span>
                                    <span class="fw-bold ms-auto" >Ksh. {{ formatNumber(delivery_data.price) }} </span>
                                </li>

                                <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>

                                <li id="total-price" class="list-group-item border-0 d-flex pt-3 pb-3">
                                    <font-awesome-icon class="icon-success d-none me-2 mt-1" :icon="['fa', 'check-circle']" />
                                    <span class="fw-bold text-uppercase text-orange"> Total Price </span>
                                    <span class="fw-bold ms-auto text-orange" >Ksh. {{ total_price }} </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="modal-footer border-0">
                        <button type="button" class="btn btn-secondary delivery-button btn-sm" data-bs-dismiss="modal" @click="showConfirm('mpesa')">
                            Cancel <font-awesome-icon class="icon ms-2" :icon="['fa', 'times']" />     
                        </button>
                        <button type="button" class="btn btn-success delivery-button btn-sm" @click="payCash()" data-bs-dismiss="modal">
                            Confirm <font-awesome-icon class="icon ms-2" :icon="['fa', 'check-circle']" /> 
                        </button>
                    </div>
                </div>
            </div>
        </div>    
    </div>
</template>

<style scoped>
    .list-group-item {
        cursor: pointer;
    }
</style>

<script>
import Status from '../Status'
import PushNotification from '../../assets/js/push_notifications.js'

export default {
    name: 'DeliveryDetails',
    components: {
        Status,
    },
    mixins: [PushNotification],
    props: {
        screen: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            status_data: {
                "success": false,
                "success_message": null,
                "error": false,
                "error_message": null,
                "loading": null   
            },
            delivery_data: {},
            total_price: 0,
            payment_details_screen: 0,
            payment_modal_screen: 0,
            change_phone_number: false,
            phone_number: null,
            payment_method: null,
            prescribed: null
        }
    },
    watch: {
        cart_stock: function() {
            this.prescribed = this.ifPrescribed()
            this.calculateTotal()
        },
        user: function() {
            this.phone_number = this.user.phone_number
        }
    },
    computed: {
      cart_stock () {
        return this.$store.state.cart_stock
      },
      user() {
        return this.$store.state.user
      }       
    },    
    mounted() {
        this.getLatestDelivery()
        this.$store.dispatch("getUser")
    },
    methods: {      
        formatNumber(num) {
            let value = Math.trunc(num)
            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        showConfirm(value) {
            let color = 'success'
            let id = ''

            if(value == 'cash' || value == 'buy_goods') {
                id = 'cash' 
            } else if (value == 'mpesa') {
                id = 'mpesa'
            }

            // set payment method
            this.payment_method = value

            let event = document.getElementById(id)
            
            if(!event.classList.contains('bg-' + color)) {     
                event.classList.add('bg-' + color, 'text-white');
                event.querySelector('.icon').classList.remove('text-' + color + '');
                event.querySelector('.icon').classList.add('text-white'); 
                event.querySelector('.icon-'+ color).classList.remove('d-none');
            } else {
                event.classList.remove('bg-' + color, 'text-white');
                event.querySelector('.icon').classList.add('text-' + color);
                event.querySelector('.icon').classList.remove('text-white');
                event.querySelector('.icon-' + color).classList.add('d-none');
            }
        },      
        payMPesa() {
            this.status_data.loading = true

            let data = {
                amount: this.total_price,
                payment_type: 'mpesa_stk',
                phone_number: this.phone_number          
            }

            this.axios.post('api/mpesa-prompt', data).then(response => {
                this.status_data.loading = false
                this.status_data.error = false
                this.subscribe('Your order was successfully created!')

                setTimeout(() => {
                    this.$emit('update:screen', 3)
                }, 1500);

                console.log(response)
            }).catch(errors => {
                this.status_data.loading = false
                this.status_data.error = true
                this.status_data.error_message = 'Prompt not sent.'

                if(errors.data.errorMessage) {
                    this.status_data.error_message = 'Prompt not sent. ' + errors.data.errorMessage
                }
            })
        },
        payCash() {
            this.status_data.loading = true
            
            let data = {
                amount: this.total_price,
                payment_type: this.payment_method,
                delivery_id: this.delivery_data.id
            }

            this.axios.post('api/payment', data).then(() => {
                this.status_data.loading = false
                this.status_data.error = false
                this.status_data.success = true
                this.status_data.success_message = "Success! Your delivery has started!"
                this.subscribe('Your order was successfully created!')

                setTimeout(() => {
                    this.$emit('update:screen', 3)
                }, 1500);

            }).catch(errors => {
                this.status_data.loading = false
                this.status_data.success = false
                this.status_data.error = true
                this.status_data.error_message = 'We weren\'t able to catch that.'

                console.log(errors);
            })            
        },        
        getLatestDelivery() {
            this.axios.get('api/latest-delivery').then(response => {
                if(response.data.data == null) {
                    this.$router.push({name: "Home"})
                }

                console.log(response.data.data)
                
                this.delivery_data = response.data.data
                this.$store.dispatch("cartItemsByDelivery", this.delivery_data.id)

            })
        },
        calculateTotal() {
            let total = 0

            this.cart_stock.forEach(element => {
                total = total + (parseInt(element.price)*parseInt(element.quantity))
            });

            this.total_price = total + parseInt(this.delivery_data.price)

            if(this.cart_stock && this.cart_stock[0].prescription_cart_id != null && this.cart_stock[0].status == 0) {
                this.total_price = this.total_price + 250
            }
        },
        ifPrescribed() {
            return this.cart_stock && this.cart_stock[0].prescription_cart_id != null && this.cart_stock[0].status == 0 ? true : false
        }
    }
}
</script>