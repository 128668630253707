<template>
    <div class="content bg-white position-fixed bottom-0 w-100" style="z-index: 1; border-top-right-radius: 5%; border-top-left-radius: 5%; border: 1px solid #eee;">

        <div class="card border-0">
            <div class="card-body mb-3">
                <h5 class="fw-bold text-center text-orange" style="letter-spacing: -0.2px">Delivery Requested!</h5>

                <p class="text-center text-muted ps-2 pe-2 mb-2">We've prompted a payment on your phone for the delivery.</p>  

                <div class="alert alert-warning alert-dismissible fade show mt-3" role="alert">
                    <h6 class="alert-heading fw-bold">Prompt didn't come?</h6>
                    <p class="mt-2 mb-0" style="font-size: 14px!important">You can follow our instructions on how to manually pay for the delivery.</p>

                    <router-link :to="{name: 'PaymentInstructions'}" class="btn btn-default border-dark fw-bold btn-sm mt-3">
                        Paybill Instructions <font-awesome-icon class="ms-2" :icon="['fa', 'angle-right']"></font-awesome-icon>
                    </router-link> 
                </div>
            </div>
        </div>

        <div class="card-footer border-0 pt-0 pb-4">
            <router-link :to="{name: 'MyDeliveries'}" class="btn bg-orange btn-sm text-white w-100 fw-bold">
                See created delivery <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentConfirmed',
}
</script>