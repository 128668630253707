<template>
    <div class="content bg-white position-fixed bottom-0 w-100" style="z-index: 1; border-top-right-radius: 5%; border-top-left-radius: 5%; border: 1px solid #eee;">
        <div class="card-header bg-transparent border-0 text-center pt-4 pb-1">
            <div class="row">
                <h6 class="text-uppercase fw-bold" style="letter-spacing: 1px">Waiting for confirmation</h6>
                <p v-if="delivery_data.price" class="fw-bold text-orange-secondary mb-0" style="font-size: 2rem">KSH. {{ formatNumber(delivery_data.price) }}</p>
            </div>
        </div>

        <div class="card-body pt-0">
            <div class="row pt-2">
                <div class="col-12 d-flex mt-3 mb-3">
                    <font-awesome-icon class="text-success me-4" :icon="['fa', 'circle-notch']"></font-awesome-icon>
                    <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px">From {{ delivery_data.delivery_from }}</p>
                </div>

                <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>

                <div class="col-12 d-flex mt-3 mb-3">
                    <font-awesome-icon class="text-success me-4" :icon="['fa', 'location-arrow']"></font-awesome-icon>
                    <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px">To {{ delivery_data.delivery_to }}</p>
                </div>

                <div class="divider rounded-pill" style="padding-top: 1px; background: #eee"></div>

                <div class="col-6 d-flex mt-3 mb-3">
                    <font-awesome-icon class="text-success me-4" :icon="['fa', 'road']"></font-awesome-icon>
                    <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px">{{ Math.trunc(delivery_data.distance/1000) }} km</p>
                </div>

                <div class="col-6 d-flex mt-3 mb-3">
                    <font-awesome-icon class="text-success me-4" :icon="['fa', 'clock']"></font-awesome-icon>
                    <p class="text-uppercase mb-0" style="font-weight: 500; letter-spacing: .3px">{{ Math.trunc(delivery_data.duration/60) }} minutes</p>
                </div>

                <div class="col-12 d-flex mt-2">
                    <p class="ms-auto text-uppercase fw-bold text-warning mb-0">
                        <span class="text-warning mb-0">&bull;</span> {{ delivery_data.status }}
                    </p>
                </div>
            </div>
        </div> 

        <div class="card-footer border-0 pt-0 pb-4">
            <button class="btn bg-orange btn-sm text-white w-100 fw-bold" @click="this.$emit('update:screen', 1)">
                Finish Making Delivery <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DeliveryDetails',
    props: {
        screen: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            delivery_data: {}
        }
    },
    mounted() {
        this.getLatestDelivery()
    },
    methods: {
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        getLatestDelivery() {
            this.axios.get('api/latest-delivery').then(response => {
                this.delivery_data = response.data.data
                console.log(response.data);
            })
        } 
    }
}
</script>